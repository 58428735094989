import React from "react"
import Header from "./header"
import Footer from "./footer"
import { MDXProvider } from "@mdx-js/react"
import { Box, Themed } from "theme-ui"
import BgImage from "../images/bg-violencies.png"
import Seo from "./seo"

const DefaultMDXLayout = ({ children }) => {

  const bgImage = `url(${BgImage})`

  return (
    <Themed.root>
      <Seo
        title="Jornadas libres de violencias machistas octubre 2021"
        description="Repositorio de Jornadas libres de violencias machistas Voces feministas desde Cataluña, Estado Español y América Latina"
      />
      <Box
        as={`div`}
        sx={{
          backgroundImage: `${bgImage}`,
          backgroundPosition: `center`,
          backgroundRepeat: `repeat`,
        }}
      >
      <Header />
      <main><MDXProvider>{children}</MDXProvider></main>
      <Footer />
      </Box>
    </Themed.root>
  )

}

export default DefaultMDXLayout