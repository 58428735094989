import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/antonio-escritorio/Documents/moare/sites-estaticos/libres-de-violencias/src/components/default-mdx-layout.js";
import { Box, Container, Grid, Themed } from "theme-ui";
import { Link } from "gatsby";
import ContainerNarrow from "../components/container-narrow";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
      <Grid gap={[5, 5, 5, 7, 7, 8]} sx={{
        gridTemplateColumns: `repeat(auto-fit, minmax(17.08rem, 1fr))`,
        py: 6
      }} mdxType="Grid">
        <Box sx={{
          ul: {
            padding: 0,
            margin: 0,
            listStyle: `none`
          },
          li: {
            marginBottom: [4, 4, 4, 3, 3, 3]
          }
        }} mdxType="Box">
          <h1>{`Plantemos cara a las violencias sexuales en jóvenes y adolescentes: respuestas y retos desde diferentes ámbitos y geografías.`}</h1>
          <p>{`Las violencias sexuales atraviesan a jóvenes y adolescentes de todo el mundo y para abordarlas es necesario adaptar nuestros focos de atención a sus realidades. `}</p>
          <p><strong parentName="p">{`Participantes`}</strong></p>
          <ul>
            <li parentName="ul">
              <Themed.a as={Link} to={`/gema-manzanares`}>Gema Manzanares</Themed.a>.<br /><small>Cofundadora de EnRedadas por el Arte y la Tecnología (Nicaragua)</small>
            </li>
            <li parentName="ul">
              <Themed.a as={Link} to={`/emilia-salazar-chavez`}>Emilia Salazar Chávez</Themed.a>.<br /><small>Socióloga feminista e integrante del equipo DEMUS (Perú)</small>
            </li>
            <li parentName="ul">
              <Themed.a as={Link} to={`/barbara-biglia`}>Barbara Biglia</Themed.a>.<br /><small>Profesora del departamento de Pedagogía de la Universitat Rovira i Virgili e investigadora principal del proyecto SeGReVUni (Tarragona)</small>
            </li>
          </ul>
          <p><strong parentName="p">{`Moderación`}</strong></p>
          <p>{`Laia Serra.`}<br /><small>{`Abogada experta en violencias.`}</small></p>
        </Box>
        <Box sx={{
          ".mdx-embed": {
            backgroundColor: `primary`,
            padding: 4
          }
        }} mdxType="Box">
          <YouTube youTubeId="JwtmZ37QCu8" mdxType="YouTube" />
          <Themed.p><small>Resumen</small></Themed.p>
        </Box>
      </Grid>
    </Container>
    <ContainerNarrow mdxType="ContainerNarrow">
      <p>{`Con `}<strong parentName="p">{`Gema Manzanares`}</strong>{` nos adentramos en las violencias digitales, una de las que más padecen las jóvenes y de las más desestimadas de forma generalizada, porque al manifestarse en lo «virtual» no son consideradas como «reales». Gema propone que la visibilización de éstas en nuestro imaginario, la educación digital desde una visión no estigmatizadora ni moralista y el acompañamiento a las adolescentes serán claves para abordar estas violencias. `}</p>
      <p>{`Con `}<strong parentName="p">{`Emilia Salazar`}</strong>{` descubrimos el proyecto DEMUS, que trabajan en procesos formativos feministas para niñas en zonas rurales del Perú. La importancia de la educación feminista con niñas y adolescentes atravesadas por la violencia sexual y el trabajar la violencia de género a nivel territorial para garantizar vidas libres son los ejes que Emilia desarrolla en su presentación a través de ejemplos concretos de intervención. `}</p>
      <p>{`Finalmente, con `}<strong parentName="p">{`Barbara Biglia`}</strong>{` reflexionamos sobre las violencias sexuales en contextos universitarios. Observamos una educación sexual que no se adapta a las vidas de las jóvenes del presente. Por esta razón, Barbara plantea que es necesario trabajar desde sus lenguajes, más cercanos al mundo digital, hablar de violencias ejercidas por personas conocidas, revisar los protocolos y evitar homogeneizar el discurso de las violencias machistas.`}</p>
      <p>{`La experiencia de estas tres voces feministas y las aportaciones y preguntas del público nos dan herramientas, y también nos proponen muchos retos, para seguir trabajando las violencias sexuales con jóvenes y adolescentes. `}</p>
      <Box sx={{
        ".mdx-embed": {
          backgroundColor: `primary`,
          padding: 4,
          mt: 6
        }
      }} mdxType="Box">
        <YouTube youTubeId="ffFGToJaNhw" mdxType="YouTube" />
        <Themed.p><small>Mesa redonda</small></Themed.p>
      </Box>
    </ContainerNarrow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      