import React from "react"
import { Box } from "theme-ui"

const ContainerNarrow = ({ children }) => {

  return (
    <Box
      as={`div`}
      sx={{
        margin: `0 auto`,
        maxWidth: [
          `phoneMaxWidht`,
          `phoneMaxWidht`,
          `tabletMaxWidth`,
          `calc(100vw - 38.43rem)`,
          `calc(100vw - 38.43rem)`,
          `calc(100vw - 57.64rem)`
        ],
        "p, li": {
          fontSize: [`inherit`,`inherit`,`inherit`,2,2,3],
        }
      }}
    >
      {children}
    </Box>
  )

}

export default ContainerNarrow